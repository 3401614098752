module.exports = [
  {
    path: '/innstillinger',
    name: 'Settings',
    components: {
      default: () => import('@/views/pages/settings/Overview.vue')
    },
    meta: {
      admin: true
    }
  },

  {
    path: '/innstillinger/schematics',
    name: 'SettingsSchematics',
    components: {
      default: () => import('@/views/pages/settings/schematics/Index.vue'),
      sidebar: () => import('@/views/sidebars/Settings.vue')
    },
    meta: {
      admin: true
    }
  },

  {
    path: '/innstillinger/konfigurasjon',
    name: 'SettingsConfig',
    components: {
      default: () => import('@/views/pages/settings/config/Overview.vue'),
      sidebar: () => import('@/views/sidebars/Settings.vue')
    },
    meta: {
      admin: true
    }
  },

  {
    path: '/innstillinger/konfigurasjon/marked',
    name: 'SettingsConfigMarket',
    components: {
      default: () => import('@/views/pages/settings/config/market/Overview.vue'),
      sidebar: () => import('@/views/sidebars/Settings.vue')
    },
    meta: {
      admin: true
    }
  },

  {
    path: '/innstillinger/konfigurasjon/krim',
    name: 'SettingsConfigCrime',
    components: {
      default: () => import('@/views/pages/settings/config/crime/Overview.vue'),
      sidebar: () => import('@/views/sidebars/Settings.vue')
    },
    meta: {
      admin: true
    }
  },

  {
    path: '/innstillinger/konfigurasjon/jobb',
    name: 'SettingsConfigWork',
    components: {
      default: () => import('@/views/pages/settings/config/work/Overview.vue'),
      sidebar: () => import('@/views/sidebars/Settings.vue')
    },
    meta: {
      admin: true
    }
  },

  {
    path: '/innstillinger/konfigurasjon/dodsmeldinger',
    name: 'SettingsConfigDeathMessages',
    components: {
      default: () => import('@/views/pages/settings/config/deathmessages/Overview.vue'),
      sidebar: () => import('@/views/sidebars/Settings.vue')
    },
    meta: {
      admin: true
    }
  },

  {
    path: '/innstillinger/tilganger',
    name: 'SettingsPermissions',
    components: {
      default: () => import('@/views/pages/settings/permissions/Overview.vue'),
      sidebar: () => import('@/views/sidebars/Settings.vue')
    },
    meta: {
      admin: true
    }
  },

  {
    path: '/innstillinger/konsoll',
    name: 'SettingsConsole',
    components: {
      default: () => import('@/views/pages/settings/Console.vue')
    },
    meta: {
      admin: true
    }
  },

  {
    path: '/innstillinger/spillere',
    name: 'SettingsPlayers',
    components: {
      default: () => import('@/views/pages/settings/Players.vue')
    },
    meta: {
      admin: true
    }
  },

  {
    path: '/innstillinger/spillere/:username',
    name: 'SettingsPlayer',
    components: {
      default: () => import('@/views/pages/settings/player/Overview.vue')
    },
    meta: {
      admin: true
    }
  },

  {
    path: '/innstillinger/eiendom',
    name: 'SettingsProperties',
    components: {
      default: () => import('@/views/pages/settings/Properties.vue')
    },
    meta: {
      admin: true
    }
  }
]
