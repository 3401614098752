import Vue from 'vue'
import Numeral from 'numeral'

import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

Vue.config.productionTip = false

// Socket
import VueSocket from 'vue-socket.io-extended'
import Socket from 'socket.io-client'
Vue.use(VueSocket, Socket(process.env.VUE_APP_SOCKET_URL), { store })

// Animate when in view
import VueAos from 'vue-aos'
Vue.component('l-aos', VueAos)

// EnlargeableImage
import EnlargeableImage from '@diracleo/vue-enlargeable-image'
Vue.component('EnlargeableImage', EnlargeableImage)

// Animate number
import AnimatedNumber from 'animated-number-vue'
Vue.component('a-number', AnimatedNumber)

// Hot key
import VueHotkey from 'v-hotkey'
Vue.use(VueHotkey)

// Tooltips
Vue.directive('tooltip', Tooltip)

// Number
Vue.filter('number', string => {
  return Numeral(string).format('0,0')
})

// Uppercase string
Vue.filter('uppercase', string => {
  return string.toUpperCase()
})

// Format date string
import DayJS from 'dayjs'
import dayJsRelativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/nb'
DayJS.locale( 'nb' )
DayJS.extend(dayJsRelativeTime)

Vue.filter('date', (date, format) => {
  if( !format ) format = 'HH:mm'
  return DayJS( date ).format( format )
})

Vue.filter('timeSince', (date) => {
  return DayJS().to(DayJS(date))
})

// Amount of seconds to readable
Vue.filter('secondsToFriendly', seconds => {
  return Numeral(seconds).format('00:00:00')
})

Vue.use(PrimeVue, {
  locale: {
    accept: 'Ja',
    reject: 'Nei'
  }
})
Vue.use(ToastService)
Vue.use(ConfirmationService)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
