import Api from '@/api'

export default {

  async list( cb ) {
    const result = await Api.get( 'v1/servers' )
    cb( result )
  },

  async restart( id, cb ) {
    const result = await Api.get( `v1/servers/${id}/restart` )
    cb( result )
  }

}
