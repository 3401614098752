import { mapState } from 'vuex'

const d_sounds = {
  card_draw: require('@/assets/sounds/card_draw.mp3'),
  card_placed: require('@/assets/sounds/card_placed.mp3'),
  card_start: require('@/assets/sounds/card_start.mp3'),
  card_turned: require('@/assets/sounds/card_turned.mp3'),
  roulette_start: require('@/assets/sounds/roulette_start.mp3'),
  win_jackpot: require('@/assets/sounds/win_jackpot.mp3'),
  win_jackpot2: require('@/assets/sounds/win_jackpot2.mp3'),
  loss: require('@/assets/sounds/loss.mp3'),
  win: require('@/assets/sounds/win.mp3'),
  chat_alert: require('@/assets/sounds/chat_alert.mp3')
}

export default {

  computed: {
    ...mapState({
      sound: state => state.sound,
      user: state => state.user
    }),

    hasARole() {
      if ((this.user.role && this.user.role.id) || this.user.roles.length) return true
      return false
    }
  },

  methods: {
    playSound(file) {
      const sounds = [
        'card_draw',
        'card_placed',
        'card_start',
        'card_turned',
        'roulette_start',
        'win_jackpot',
        'win_jackpot2',
        'loss',
        'win',
        'chat_alert'
      ]

      if (!this.sound.muted && sounds.includes(file)) {
        var audio = new Audio(d_sounds[file])
        audio.volume = this.sound.volume / 100
        audio.play().catch(() => { return false })
      }
    },

    getPlayerSkin(uuid, size = 40, type = '', direction = '') {
      var url = `${process.env.VUE_APP_RENDERS_URL}avatar/${uuid}?woho`
      if (type) url += '&type=' + type
      if (direction) url += '&direction=' + direction
      if (size) url += '&size=' + size
      return url
    },

    hasPermission(permission) {
      return (Object.prototype.hasOwnProperty.call(this.user.permissions, permission)) ? true : false
    },

    hasRole(role) {
      if (!this.user.roles) return false
      if (this.user.roles.find(r => r.id === role)) return true
      return false
    }
  }

}
