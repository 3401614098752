import Vue from 'vue'
import Vuex from 'vuex'

import modules from '@/store/modules.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modal: null,
    sound: {
      muted: localStorage.getItem('settings.sound.muted') || false,
      volume: localStorage.getItem('settings.sound.volume') || 10
    },
    error: {
      offline: false,
      code: null,
      message: null
    }
  },
  mutations: {
    modal(state, modal) {
      state.modal = modal
    },

    toggleSound(state) {
      state.sound.muted = !state.sound.muted
      if (!state.sound.muted) localStorage.removeItem('settings.sound.muted')
      else localStorage.setItem('settings.sound.muted', state.sound.muted)
    },

    setVolume(state, volume) {
      state.sound.volume = volume
      localStorage.setItem('settings.sound.volume', state.sound.volume)
    },

    setApiStatus(state, status) {
      state.error.offline = status.offline
      state.error.code = status.code
      state.error.message = status.message
    }
  },
  actions: {
    toggleSound({ commit }) {
      commit('toggleSound')
    },

    setVolume({ commit }, volume) {
      commit('setVolume', volume)
    },

    openModal({ commit }, modal) {
      commit('modal', modal)
    },

    closeModal({ commit }) {
      commit('modal', null)
    },

    setApiStatus({ commit }, status) {
      commit('setApiStatus', status)
    }
  },
  modules
})
