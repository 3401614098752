import Api from '@/api'

export default {

  async get( username, cb ) {
    const result = await Api.get( 'v1/players/' + username )
    cb( result )
  },

  async playTime( username, cb ) {
    const result = await Api.get( 'v1/players/' + username + '/playtime' )
    cb( result )
  },

  async ec( username, cb ) {
    const result = await Api.get( 'v1/players/' + username + '/ec' )
    cb( result )
  },

  async ecDailyChart( username, cb ) {
    const result = await Api.get( 'v1/players/' + username + '/charts/ec/daily' )
    cb( result )
  },

  async achievements( username, cb ) {
    const result = await Api.get( 'v1/players/' + username + '/achievements' )
    cb( result )
  },

  async getStatisticsMinecraft( username, categories, cb ) {
    const result = await Api.get( 'v1/players/' + username + '/statistics/minecraft?categories=' + categories.join(',') )
    cb( result )
  },

  async list(cb) {
    const result = await Api.get('v1/players')
    cb(result)
  },

  async listOnline( cb ) {
    const result = await Api.get( 'v1/players/online' )
    cb( result )
  },

  async listStaff( cb ) {
    const result = await Api.get( 'v1/players/staff' )
    cb( result )
  }

}
