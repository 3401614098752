import players from '@/api/players'

const state = {
  online: []
}

const getters = {
  getOnlinePlayers(state) {
    return state.online
  },

  getOnlinePlayersByServer: state => (server, isOnMap = false) => {
    if (isOnMap) return state.online.filter(player => player.is_on_map && player.server.id === server && player.type && player.type.includes('server'))
    else return state.online.filter(player => player.server.id === server && player.type && player.type.includes('server'))
  },

  getOnlinePlayersByWorld: state => (server, world, isOnMap = false) => {
    if (isOnMap) {
      if(server === 'sky' || server === 'plot' || server === 'hub') return state.online.filter(player => player.is_on_map && player.server.id === server && world === player.server.world && player.type && player.type.includes('server'))
      return state.online.filter(player => player.is_on_map && player.server.id === server && world === player.server.world && player.server.coordinates.y > 55 && player.type && player.type.includes('server'))
    } else {
      if(server === 'sky' || server === 'plot' || server === 'hub') return state.online.filter(player => player.server.id === server && world === player.server.world && player.type && player.type.includes('server'))
      return state.online.filter(player => player.server.id === server && world === player.server.world && player.server.coordinates.y > 55 && player.type && player.type.includes('server'))
    }
  },

  getOnlinePlayerCount(state) {
    return state.online.length
  },

  getOnlinePlayerServersCount(state) {
    return state.online.filter(player => player.is_online).length
  },

  getOnlinePlayerCountByServer: state => server => {
    return state.online.filter(player => player.server.id === server && player.type && player.type.includes('server')).length
  },

  getOnlinePlayersOnWebsite: state => {
    return state.online.filter(player => player.type && player.type.includes('website'))
  },

  getOnlinePlayersOnServer: state => {
    return state.online.filter(player => player.type && player.type.includes('server'))
  },

  getOnlinePlayerCountOnWebsite: state => {
    return state.online.filter(player => player.type && player.type.includes('website')).length
  }
}

const actions = {
  getOnline({ commit }) {
    players.listOnline(data => {
      if (data) {
        for (const player of data) {
          commit('handlePlayer', player)
        }
      }
    } )
  },

  socket_playerOnline({ commit }, player) {
    commit('handlePlayer', player)
  },

  socket_playerOffline({ commit }, data) {
    console.log('player is now offline')
    commit('handlePlayerOffline', data)
  },

  socket_playerServerSwitch({ commit }, player) {
    commit('handlePlayer', player)
  },

  socket_playerMapToggle({ commit }, player) {
    commit('handlePlayerMapToggle', player)
  },

  socket_playerMove({ commit }, player) {
    commit('handlePlayerCoordinates', JSON.parse(player))
  }
}

const mutations = {
  // This should handle the player, only require uuid
  // and update anything that has changed
  handlePlayer(state, player) {
    if (!player) return
    if (!player.uuid) return

    const p = state.online.find(o => o.uuid === player.uuid) || {}
    if (p.uuid) {
      state.online.splice(state.online.indexOf(p), 1)
    }

    // Handle player types
    if (p && p.type && !p.type.includes(player.type)) {
      p.type = [
        player.type,
        ...p.type
      ]
    }

    if (!p.type && player.type) p.type = [player.type]
    if (!p.uuid || p.uuid != player.uuid) p.uuid = player.uuid
    if (!p.name || p.name != player.name) p.name = player.name
    if (!p.server || p.server != player.server ) p.server = player.server
    if (!p.role || p.role != player.role ) p.role = player.role
    if (!p.meta || p.meta != player.meta ) p.meta = player.meta
    if (!p.is_on_map || p.is_on_map != player.is_on_map ) p.is_on_map = player.is_on_map
    if (!p.is_online || p.is_online != player.is_online ) p.is_online = player.is_online
    if (!p.discord || p.discord != player.discord ) p.discord = player.discord

    state.online.push(p)
  },

  // This removes the type from the player, and the entire player if types is
  // empty
  handlePlayerOffline(state, player) {
    if (!player) return
    if (!player.uuid) return
    if (!player.type) return

    // Check if player exist
    const p = state.online.find(o => o.uuid === player.uuid)
    if (!p) return

    // Player does not have the type mentioned
    if (!p.type.includes(player.type)) return

    // Player logging off the last session
    if (p.type && p.type.includes(player.type) && p.type.length === 1) {
      state.online.splice(state.online.indexOf(p), 1)
    }

    // Player is logging out of one of multiple sessions
    if (p.type && p.type.includes(player.type) && p.type.length > 1) {
      state.online.splice(state.online.indexOf(p), 1)
      p.type.splice(p.type.indexOf(player.type), 1)
      p.is_online = 0
      state.online.push(p)
    }
  },

  // This updates the coordinates of a player
  handlePlayerCoordinates(state, player) {
    if (!player) return
    if (!player.name) return

    const p = state.online.find(o => o.name === player.name) || {}
    if (p.uuid) {
      state.online.splice(state.online.indexOf(p), 1)

      p.server.world = player.world
      p.server.coordinates.x = player.x
      p.server.coordinates.z = player.z
      p.server.coordinates.y = player.y

      state.online.push(p)
    }
  },

  // Player is toggling visibility on the map
  handlePlayerMapToggle(state, player) {
    if (!player.name) return

    const p = state.online.find(o => o.name === player.name) || {}
    if (p.uuid) {
      state.online.splice(state.online.indexOf(p), 1)
      p.is_on_map = player.state
      state.online.push(p)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
