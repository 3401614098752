<template>
  <div id="layout-landing" class="h-100 align-items-center" v-if="landingJoke">
    <div class="h-100 row align-items-center">

      <div class="col text-center" style="max-width: 650px; margin: 0 auto; color: rgba(255, 255, 255, 0.3);">
        <img src="@/assets/images/logo_white.png" />

        <h2 class="mt-5 text-white">Takk og farvell</h2>

        <p>Vi har i lengre tid jobbet for og lage et unikt Minecraft samfunn som
          ikke helt er som alle andre. Vi er på god vei, og har fått til mye,
          men nå er det så brått slutt.</p>

        <p>Jeg bruker mer tid på og irritere meg over
          all tiden jeg må bruke på og hjelpe de som skal hjelpe meg, aka
          staff, at all morro innen Minecraft og Elytra generelt er forsvunnet.</p>

        <p>Det blir regelrett for mye for en person; en ting er og utvikle alt fra
          A til Å, men når du også skal være support, administrator, moderator og
          plugin-redigerings-konfig-shit-drit-kjedelig-opplegg-ansvarlig, da blir
          man fort lei.</p>

        <p>Så jeg gjør det enkelt og sier takk og farvell!</p>
      </div>

      <div class="text-muted login" v-on:click="closeLanding()">
        Elendig spøk!
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LayoutLanding',

  data: function() {
    return {
      landingJoke: false
    }
  },

  methods: {
    ...mapActions([
      'openModal'
    ]),

    closeLanding() {
      this.landingJoke = false
    }
  }
}
</script>

<style>
#layout-landing {
  background-color: #42356b;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9999;
}

#layout-landing img {
  max-width: 250px;
}

#layout-landing .login {
  position: absolute;
  bottom: 15px;
  right: 25px;
  cursor: pointer;
  color: rgba( 255, 255, 255, 0.1 )!important;
}
</style>
