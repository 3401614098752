module.exports = [
  {
    path: '/spill/blackjack',
    name: 'GamesBlackjack',
    components: {
      default: () => import('@/views/pages/games/Blackjack.vue'),
      header: () => import('@/views/headers/games/Blackjack.vue')
    },
    meta: {
      layout: {
        pattern: 'curve'
      }
    }
  },

  {
    path: '/spill/roulette',
    name: 'GamesRoulette',
    components: {
      default: () => import('@/views/pages/games/Roulette.vue'),
      header: () => import('@/views/headers/games/Roulette.vue')
    },
    meta: {
      layout: {
        pattern: 'tilt'
      }
    }
  },

  {
    path: '/spill/snake',
    name: 'GamesSnake',
    components: {
      default: () => import('@/views/pages/games/Snake.vue')
    },
    meta: {
      layout: {
        hideFooter: true,
        fullPage: true
      }
    }
  },

  {
    path: '/spill/minebros',
    name: 'GamesMinebros',
    components: {
      default: () => import('@/views/pages/games/Minebros.vue')
    },
    meta: {
      layout: {
        hideFooter: true,
        fullPage: true
      }
    }
  },

  {
    path: '/spill/minebros/maker',
    name: 'GamesMinebrosMaker',
    components: {
      default: () => import('@/views/pages/games/MinebrosMaker.vue')
    },
    meta: {
      layout: {
        hideFooter: true,
        fullPage: true
      }
    }
  },

  {
    path: '/spill/uno',
    name: 'GamesUno',
    components: {
      default: () => import('@/views/pages/games/Uno.vue')
    },
    meta: {
      layout: {
        hideFooter: true,
        fullPage: true
      }
    }
  }
]
