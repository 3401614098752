import Api from '@/api'

export default {

  async get( cb ) {
    const result = await Api.get( 'v1/player' )
    cb( result )
  },

  async getStatistics( cb ) {
    const result = await Api.get( 'v1/player/statistics' )
    cb( result )
  },

  async authorize( code, cb ) {
    const token = await Api.post( 'v1/token', new URLSearchParams( {
      code: code,
      grant_type: 'authorization_code',
      client_id: process.env.VUE_APP_API_CLIENT
    } ) )

    cb( token )
  },

  async password(password) {
    return await Api.post('v1/player/password', password)
  },

  async stream(data) {
    return await Api.post('v1/player/stream', data)
  }

}
