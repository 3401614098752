import Axios from 'axios'
import Store from '@/store'

const {
  VUE_APP_API_CLIENT,
  VUE_APP_API_URL
} = process.env

// Add accessToken to all requests
Axios.interceptors.request.use( async ( config ) => {
  if( config.url.startsWith( process.env.VUE_APP_RENDERS_URL ) ) return config
  const token = localStorage.getItem( 'accessToken' )
  if( token ) config.headers.Authorization =  `Bearer ${token}`
  return config
} )

// Verify authorization code
const useAuthorizationCode = async ( code ) => {
  return await post( 'v1/token', new URLSearchParams( {
    code: code,
    grant_type: 'authorization_code',
    client_id: VUE_APP_API_CLIENT,
    redirect_uri: ["https://www.elytra.no/"]
  } ) )
}

// Use refresh token to get a new accessToken
const useRefreshToken = async ( token ) => {
  return await post( 'v1/token', new URLSearchParams( {
    refresh_token: token,
    grant_type: 'refresh_token',
    client_id: VUE_APP_API_CLIENT
  } ) )
}

// Token expired
const handleExpiredToken = async () => {
  const refreshToken = localStorage.getItem( 'refreshToken' )

  if( refreshToken ) {
    const accessToken = await useRefreshToken( refreshToken )

    if( !accessToken ) {
      Store.dispatch( 'user/logout' )
      return false
    }

    Store.dispatch( 'user/setToken', {
      access_token: accessToken.access_token,
      refresh_token: accessToken.refresh_token
    } )

    return true
  }

  return false
}

// Network error
const isNetworkError = ( err ) => {
  if( !!err.isAxiosError && !err.response ) {
    Store.dispatch( 'setApiStatus', {
      offline: true,
      code: 0,
      message: 'Vi klarer ikke og koble til Elytra sitt API for øyeblikket. Det kan virke som dette er nede. Du trenger ikke og refreshe, denne meldingen forsvinner når APIet er tilbake.'
    } )

    return true
  }

  return false
}

// GET api
const get = async ( endpoint, retries = 0 ) => {
  try {
    const result = await Axios.get( VUE_APP_API_URL + endpoint )

    if( Store.state.error.offline ) {
      Store.dispatch( 'setApiStatus', {} )
    }

    return result.data
  } catch( error ) {
    if( isNetworkError( error ) ) return

    if( Store.state.error.offline ) {
      Store.dispatch( 'setApiStatus', {} )
    }

    if( error.response.status === 401 && retries < 3 ) {
      const tokenLives = await handleExpiredToken()
      if( tokenLives ) return await get( endpoint, retries++ )
      Store.dispatch( 'user/logout' )
    }

    return null
  }
}

// POST api
const post = async ( endpoint, data, retries = 0 ) => {
  try {
    const result = await Axios.post( VUE_APP_API_URL + endpoint, data )

    if( Store.state.error.offline ) {
      Store.dispatch( 'setApiStatus', {} )
    }

    return result.data
  } catch( error ) {
    if( isNetworkError( error ) ) return

    if( Store.state.error.offline ) {
      Store.dispatch( 'setApiStatus', {} )
    }

    if( error.response.status === 401 && retries < 3 ) {
      const tokenLives = await handleExpiredToken()
      if( tokenLives ) return await post( endpoint, retries++ )
      Store.dispatch( 'user/logout' )
    }

    return null
  }
}

// DELETE api
const del = async ( endpoint, data, retries = 0 ) => {
  try {
    const result = await Axios.delete( VUE_APP_API_URL + endpoint, data )

    if( Store.state.error.offline ) {
      Store.dispatch( 'setApiStatus', {} )
    }

    return result.data
  } catch( error ) {
    if( isNetworkError( error ) ) return

    if( Store.state.error.offline ) {
      Store.dispatch( 'setApiStatus', {} )
    }

    if( error.response.status === 401 && retries < 3 ) {
      const tokenLives = await handleExpiredToken()
      if( tokenLives ) return await delete( endpoint, retries++ )
      Store.dispatch( 'user/logout' )
    }

    return null
  }
}

export default {
  get,
  post,
  del,
  useAuthorizationCode
}
