import servers from '@/api/servers'

const state = {
  servers: []
}

const getters = {
  getVisibleServers(state) {
    return state.servers.filter(s => s.is_visible)
  }
}

const actions = {
  get({ commit }) {
    servers.list(data => {
      if (data) {
        for (const server of data.servers) {
          commit('addServer', server)
        }
      }
    } )
  },

  socket_serverUpdate({ commit }, data) {
    commit('setServerState', { id: data.id, state: data.state })
  }
}

const mutations = {
  addServer(state, payload) {
    const server = state.servers.find(s => s.id === payload.id)
    if (server) {
      console.log(server)
      console.log('Server update recieved')
    } else {
      state.servers.push(payload)
    }
  },

  setServerState(state, payload) {
    const server = state.servers.find(s => s.id === payload.id)
    if (!server) return

    const index = state.servers.indexOf(server)
    state.servers[index].state = payload.state
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
