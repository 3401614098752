const state = {
  cart: [
    {
      id_product: 1,
      amount: 30,
      currency: 'NOK',
      data: {
        uuid: ''
      }
    }
  ],
  cart_value: [
    {
      currency: 'NOK',
      value: 129
    },
    {
      currency: 'EC',
      value: 500000
    }
  ],
  products: {
    digital: [],
    physical: []
  }
}

const getters = {

}

const actions = {
  addToCart() {

  }
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
