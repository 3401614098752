import user from '@/api/user'

const state = {
  uuid: null,
  name: null,
  balance: null,
  achievements: {
    completed: 0
  },
  expenses: {
    properties: 0
  },
  discord: {
    id: null
  },
  role: {
    id: null,
    name: null,
    meta: {
      color: null,
      prefix: null,
      suffix: null
    }
  },
  url: {
    twitch: null,
    youtube: null
  },
  logon_message: null,
  roles: [],
  permissions: [],
  is_online: false,
  statistics: null,
  token: {
    accessToken: localStorage.getItem('accessToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || ''
  }
}

const getters = {
}

const actions = {
  setToken({ commit }, payload) {
    commit('setToken', payload)
  },

  logout({ commit }) {
    commit('logout')
  },

  get({ state, commit }) {
    if (!state.token.accessToken) return
    user.get(data => {
      if (data) commit('setUser', data)
    })

    user.getStatistics(data => {
      if (data) commit('setStatistics', data)
    })
  },

  socket_playerBalance({ commit }, data) {
    if (data.type === 'out') commit('takeFromBalance', data.balance)
    else if (data.type === 'in') commit('addToBalance', data.balance)
  }
}

const mutations = {
  setUser(state, payload) {
    state.uuid = payload.uuid
    state.name = payload.name
    state.balance = payload.balance
    state.achievements = payload.achievements
    state.expenses = payload.expenses
    state.role.id = (payload.role && payload.role.id) ? payload.role.id : null
    state.role.name = (payload.role && payload.role.name) ? payload.role.name : null
    state.roles = (payload.roles) ? payload.roles : null
    state.url.twitch = (payload.url && payload.url.twitch) ? payload.url.twitch : null
    state.url.youtube = (payload.url && payload.url.youtube) ? payload.url.youtube : null
    state.logon_message = (payload.logon_message) ? payload.logon_message : null
    state.permissions = payload.permissions
    state.discord.id = (payload.discord && payload.discord.id) ? payload.discord.id : null
    state.role.meta.color = (payload.role && payload.role.meta && payload.role.meta.color) ? payload.role.meta.color : null
    state.role.meta.prefix = (payload.role && payload.role.meta && payload.role.meta.prefix) ? payload.role.meta.prefix : null
    state.role.meta.suffix = (payload.role && payload.role.meta && payload.role.meta.suffix) ? payload.role.meta.suffix : null
    state.is_online = payload.is_online
  },

  setStatistics(state, payload) {
    state.statistics = payload
  },

  setToken(state, payload) {
    state.token.accessToken = payload.access_token
    state.token.refreshToken = payload.refresh_token

    localStorage.setItem('accessToken', payload.access_token)
    localStorage.setItem('refreshToken', payload.refresh_token)

    this._vm.$socket.client.emit('token', payload.access_token)
  },

  addToBalance(state, balance) {
    state.balance = state.balance + parseInt(balance)
  },

  takeFromBalance(state, balance) {
    state.balance = state.balance - parseInt(balance)
  },

  logout(state) {
    state.uuid = null
    state.name = null
    state.balance = null

    state.role.id = null
    state.role.name = null
    state.role.meta.color = null
    state.role.meta.prefix = null
    state.role.meta.suffix = null
    state.roles = []
    state.is_online = false

    state.token.accessToken = null
    state.token.refreshToken = null

    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
