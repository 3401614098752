const state = {
  map: {
    id: null,
    world: null
  },
  maps: []
}

const getters = {

}

const actions = {

}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
