module.exports = [
  {
    path: '/spillere/:username',
    name: 'Player',
    components: {
      default: () => import('@/views/pages/player/Overview.vue'),
      sidebar: () => import('@/views/sidebars/Player.vue'),
      header: () => import('@/views/headers/Player.vue')
    },
    meta: {
      noMarginHeader: true,
      noPaddingHeader: true,
      subTab: 'overview'
    }
  },

  {
    path: '/spillere/:username/achievements',
    name: 'PlayersAchievements',
    components: {
      default: () => import('@/views/pages/player/Achievements.vue'),
      sidebar: () => import('@/views/sidebars/Player.vue'),
      header: () => import('@/views/headers/Player.vue')
    },
    meta: {
      noMarginHeader: true,
      noPaddingHeader: true,
      subTab: 'achievements'
    }
  },

  {
    path: '/spillere/:username/ec',
    name: 'PlayersEc',
    components: {
      default: () => import('@/views/pages/player/Ec.vue'),
      sidebar: () => import('@/views/sidebars/Player.vue'),
      header: () => import('@/views/headers/Player.vue')
    },
    meta: {
      noMarginHeader: true,
      noPaddingHeader: true,
      subTab: 'ec'
    }
  },

  {
    path: '/spillere/:username/statistikk',
    name: 'PlayersStatistics',
    components: {
      default: () => import('@/views/pages/player/statistics/General.vue'),
      sidebar: () => import('@/views/sidebars/Player.vue'),
      header: () => import('@/views/headers/Player.vue')
    },
    meta: {
      noMarginHeader: true,
      noPaddingHeader: true,
      stopToTop: true,
      subTab: 'statistics'
    }
  },

  {
    path: '/spillere/:username/statistikk/items',
    name: 'PlayersStatisticsItems',
    components: {
      default: () => import('@/views/pages/player/statistics/Items.vue'),
      sidebar: () => import('@/views/sidebars/Player.vue'),
      header: () => import('@/views/headers/Player.vue')
    },
    meta: {
      noMarginHeader: true,
      noPaddingHeader: true,
      stopToTop: true,
      subTab: 'statistics'
    }
  },

  {
    path: '/spillere/:username/statistikk/mobs',
    name: 'PlayersStatisticsMobs',
    components: {
      default: () => import('@/views/pages/player/statistics/Mobs.vue'),
      sidebar: () => import('@/views/sidebars/Player.vue'),
      header: () => import('@/views/headers/Player.vue')
    },
    meta: {
      noMarginHeader: true,
      noPaddingHeader: true,
      stopToTop: true,
      subTab: 'statistics'
    }
  }
]
