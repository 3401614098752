module.exports = [
  {
    path: '/',
    name: 'Home',
    components: {
      default: () => import('@/views/pages/Home.vue'),
      header: () => import('@/views/headers/Home.vue')
    },
    meta: {
      title: 'Velkommen',
      layout: {
        pattern: 'cube'
      }
    }
  },

  {
    path: '/chat',
    name: 'Chat',
    component: () => import('@/views/pages/Chat.vue'),
    meta: {
      layout: {
        pattern: 'wave',
        expandNavbar: true
      }
    }
  },

  {
    path: '/staff',
    name: 'Staff',
    components: {
      default: () => import('@/views/pages/Staff.vue'),
      header: () => import('@/views/headers/Staff.vue')
    },
    meta: {
      title: 'Personalet',
      layout: {
        pattern: 'tilt'
      }
    }
  },

  {
    path: '/søknader',
    name: 'Applications',
    components: {
      default: () => import('@/views/pages/applications/Overview.vue'),
      header: () => import('@/views/headers/applications/Overview.vue')
    },
    meta: {
      title: 'Søknader',
      layout: {
        pattern: 'wave'
      }
    }
  },
  {
    path: '/søknader/ny',
    name: 'ApplicationsCreate',
    component: () => import('@/views/pages/applications/Create.vue')
  },
  {
    path: '/søknader/:id',
    name: 'ApplicationsView',
    component: () => import('@/views/pages/applications/Application.vue')
  },

  {
    path: '/butikk',
    name: 'Shop',
    components: {
      default: () => import('@/views/pages/Shop.vue'),
      header: () => import('@/views/headers/Shop.vue')
    },
    meta: {
      layout: {
        pattern: 'arrow'
      }
    }
  },

  {
    path: '/kart',
    name: 'Map',
    component: () => import('@/views/pages/Map.vue'),
    meta: {
      title: 'Kart',
      layout: {
        hideFooter: true,
        fullPage: true
      }
    }
  },

  {
    path: '/regler',
    name: 'Rules',
    components: {
      default: () => import('@/views/pages/Rules.vue'),
      header: () => import('@/views/headers/Rules.vue')
    }
  }
]
