module.exports = [
  {
    path: '/statistikk',
    name: 'Statistics',
    components: {
      default: () => import('@/views/pages/statistics/General.vue'),
      header: () => import('@/views/headers/statistics/Overview.vue')
    },
    meta: {
      title: 'Statistikk for EC',
      layout: {
        pattern: 'triangle'
      },
      stopToTop: true
    }
  },

  {
    path: '/statistikk/items',
    name: 'StatisticsItems',
    components: {
      default: () => import('@/views/pages/statistics/Items.vue'),
      header: () => import('@/views/headers/statistics/Overview.vue')
    },
    meta: {
      title: 'Statistikk for EC',
      layout: {
        pattern: 'triangle'
      },
      stopToTop: true
    }
  },

  {
    path: '/statistikk/mobs',
    name: 'StatisticsMobs',
    components: {
      default: () => import('@/views/pages/statistics/Mobs.vue'),
      header: () => import('@/views/headers/statistics/Overview.vue')
    },
    meta: {
      title: 'Statistikk for EC',
      layout: {
        pattern: 'triangle'
      },
      stopToTop: true
    }
  },

  {
    path: '/statistikk/ec',
    name: 'StatisticsEc',
    components: {
      default: () => import('@/views/pages/Tips.vue'),
      header: () => import('@/views/headers/statistics/Ec.vue')
    },
    meta: {
      title: 'Statistikk for EC',
      layout: {
        pattern: 'tilt'
      }
    }
  }
]
