import chat from '@/api/chat'

const state = {
  loading: true,
  unread: 0,
  reading: false,
  statistics: {
    total: {
      messages: 0,
      filtered_words: 0
    },
    average_per_day: 0
  },
  messages: []
}

const getters = {

  getMessages: state => {
    var result = {}
    var id = 0

    for (const message of state.messages) {
      const mTimestamp = new Date(message.created_at)
      const mDate = `${mTimestamp.getMonth() + 1}.${mTimestamp.getDate()}.${mTimestamp.getFullYear()}`

      // A regular message
      if (message.message) {
        const nmessage = {
          id: message.id,
          server: message.server,
          player: message.player,
          messages: [
            {
              id: message.id,
              message: message.message,
              created_at: message.created_at
            }
          ],
          created_at: message.created_at
        }

        if (!result[mDate]) {
          result[mDate] = {
            id: id,
            message: []
          }
          id++
        }

        const lastmessage = result[mDate].message[result[mDate].message.length - 1]
        if (lastmessage && lastmessage.player.uuid === message.player.uuid && (new Date(message.created_at) - new Date(lastmessage.created_at)) < 5 * 60 * 1000) {
          result[mDate].message[result[mDate].message.length - 1].messages.push( {
            message: message.message,
            created_at: message.created_at
          } )
        } else {
          result[mDate].message.push(nmessage)
        }
      }

      // A command
      if (message.command) {
        const nmessage = {
          id: message.id,
          server: message.server,
          player: message.player,
          command: message.command,
          created_at: message.created_at
        }

        result[mDate].message.push(nmessage)
      }

    }

    return result
  }

}

const actions = {

  socket_chatMessage({ commit }, message) {
    if (message.message) {
      commit('addMessage', message)
    }
  },

  fetchMessages({ commit }) {
    chat.getMessages(result => {
      if (result) commit('setMessages', result)
    })

    chat.statistics(result => {
      if (result) commit('setStatistics', result)
    })
  },

  setRead({ commit }) {
    commit('setRead')
  },

  setReadState({ commit }, state) {
    commit('setReadState', state)
  }

}

const mutations = {

  addMessage(state, payload) {
    state.messages.push(payload)
    if (!state.reading) state.unread++
  },

  addCommand(state, payload) {
    state.messages.push({
      player: payload.player,
      command: payload.command,
      created_at: new Date()
    })
  },

  setMessages(state, payload) {
    state.loading = false
    state.messages = payload.messages.reverse()
  },

  setStatistics(state, payload) {
    state.statistics.total = payload.total
    state.statistics.average_per_day = payload.average_per_day
  },

  setRead(state) {
    state.unread = 0
  },

  setReadState (state, payload) {
    state.reading = payload
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
