module.exports = [
  {
    path: '/hjelp',
    name: 'Help',
    components: {
      default: () => import('@/views/pages/help/Overview.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/roller',
    name: 'HelpRoles',
    components: {
      default: () => import('@/views/pages/help/Roles.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/ec',
    name: 'HelpEc',
    components: {
      default: () => import('@/views/pages/help/ec/Readme.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/auksjon',
    name: 'HelpAuction',
    components: {
      default: () => import('@/views/pages/help/Auction.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/ompalompa',
    name: 'HelpOmpaLompa',
    components: {
      default: () => import('@/views/pages/help/ompalompa/Index.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/ompalompa/fisker',
    name: 'HelpOmpaLompaFisher',
    components: {
      default: () => import('@/views/pages/help/ompalompa/Fisher.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/ompalompa/tømrer',
    name: 'HelpOmpaLompaLumberjack',
    components: {
      default: () => import('@/views/pages/help/ompalompa/Lumberjack.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/ompalompa/bonde',
    name: 'HelpOmpaLompaFarmer',
    components: {
      default: () => import('@/views/pages/help/ompalompa/Farmer.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/poeng',
    name: 'HelpPoints',
    components: {
      default: () => import('@/views/pages/help/Points.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/discord/kommandoer',
    name: 'HelpDiscordCommands',
    components: {
      default: () => import('@/views/pages/help/discord/Commands.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/discord/verifiser',
    name: 'HelpDiscordVerify',
    components: {
      default: () => import('@/views/pages/help/discord/Verify.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/eiendom',
    name: 'HelpProperty',
    components: {
      default: () => import('@/views/pages/help/Property.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/rolle/bygger',
    name: 'HelpRolesBuilder',
    components: {
      default: () => import('@/views/pages/help/roles/Builder.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/rolle/elytron',
    name: 'HelpRolesBuilder',
    components: {
      default: () => import('@/views/pages/help/roles/Elytron.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/oneblock/faq',
    name: 'HelpOneblockFaq',
    components: {
      default: () => import('@/views/pages/help/oneblock/Faq.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/stream',
    name: 'HelpStream',
    components: {
      default: () => import('@/views/pages/help/stream/Index.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/stream/overlay',
    name: 'HelpStreamOverlay',
    components: {
      default: () => import('@/views/pages/help/stream/Overlay.vue'),
      sidebar: () => import('@/views/sidebars/Help.vue')
    }
  },

  {
    path: '/hjelp/kommandoer',
    name: 'HelpCommands',
    components: {
      default: () => import('@/views/pages/help/commands/Index.vue'),
      sidebar: () => import('@/views/sidebars/Commands.vue')
    }
  },

  {
    path: '/hjelp/kommandoer/eiendom',
    name: 'HelpCommandsProperty',
    components: {
      default: () => import('@/views/pages/help/commands/Property.vue'),
      sidebar: () => import('@/views/sidebars/Commands.vue')
    }
  },

  {
    path: '/hjelp/kommandoer/økonomi',
    name: 'HelpCommandsEconomy',
    components: {
      default: () => import('@/views/pages/help/commands/Economy.vue'),
      sidebar: () => import('@/views/sidebars/Commands.vue')
    }
  },

  {
    path: '/hjelp/kommandoer/marked',
    name: 'HelpCommandsMarket',
    components: {
      default: () => import('@/views/pages/help/commands/Market.vue'),
      sidebar: () => import('@/views/sidebars/Commands.vue')
    }
  },

  {
    path: '/hjelp/kommandoer/teleport',
    name: 'HelpCommandsTeleport',
    components: {
      default: () => import('@/views/pages/help/commands/Teleport.vue'),
      sidebar: () => import('@/views/sidebars/Commands.vue')
    }
  },

  {
    path: '/hjelp/kommandoer/vote',
    name: 'HelpCommandsVote',
    components: {
      default: () => import('@/views/pages/help/commands/Vote.vue'),
      sidebar: () => import('@/views/sidebars/Commands.vue')
    }
  },

  {
    path: '/hjelp/kommandoer/finn',
    name: 'HelpCommandsAuctionhouse',
    components: {
      default: () => import('@/views/pages/help/commands/Auctionhouse.vue'),
      sidebar: () => import('@/views/sidebars/Commands.vue')
    }
  },

  {
    path: '/hjelp/kommandoer/private-meldinger',
    name: 'HelpCommandsPM',
    components: {
      default: () => import('@/views/pages/help/commands/Pm.vue'),
      sidebar: () => import('@/views/sidebars/Commands.vue')
    }
  }
]
