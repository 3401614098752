module.exports = [
  {
    path: '/highscore/ec',
    name: 'HighscoreEc',
    components: {
      default: () => import('@/views/pages/Tips.vue'),
      header: () => import('@/views/headers/highscore/Ec.vue')
    },
    meta: {
      title: 'Highscore for EC',
      layout: {
        pattern: 'book'
      }
    }
  },

  {
    path: '/highscore/parkour',
    name: 'HighscoreParkour',
    components: {
      default: () => import('@/views/pages/Tips.vue'),
      header: () => import('@/views/headers/highscore/Parkour.vue')
    },
    meta: {
      title: 'Highscore for parkour',
      layout: {
        pattern: 'book'
      }
    }
  }
]
