<template>
  <div id="app" class="d-flex flex-column" :class="{ _modal: modal }">

    <div class="bg-danger animate__animated animate__slideInDown" style="color: rgba(255,255,255,0.7);" v-if="error.offline">
      <div class="py-3">
        <div class="container">{{ error.message }}</div>
      </div>
    </div>

    <div class="position-relative">
      <!-- Navbar -->
      <nav class="navbar navbar-expand-lg navbar-dark" :class="{ expanded: isNavbarExpanded, 'bg-primary': !this.$route.meta.admin, 'bg-danger': this.$route.meta.admin }">
        <component :is="getNavbar" />
      </nav>

      <!-- Header -->
      <div class="page-header" :class="{ 'bg-primary': !this.$route.meta.noColorInHeader, 'p-0': this.$route.meta.noPaddingHeader }" v-if="isHeader">
        <router-view name="header" />
      </div>

      <!-- Pattern -->
      <div class="pattern" v-if="getPattern">
        <component :is="getPattern" />
      </div>
    </div>

    <!-- Content -->
    <div class="flex-fill">
      <div class="h-100" :class="{ container: !isFullPage }">
        <div class="h-100" :class="{ row: !isFullPage }">

          <div class="col-md-3 sidebar" v-if="isSidebar">
            <router-view name="sidebar" />
          </div>

          <div class="content h-100" :class="{ col: !isFullPage }">
            <router-view />
          </div>

        </div>
      </div>
    </div>

    <!-- Pattern -->
    <div class="pattern" v-if="getPattern">
      <component :is="getPattern" flipped />
    </div>

    <!-- Footer -->
    <div class="footer" v-if="isFooter">
      <div class="inner-footer" style="overflow: hidden; position: relative;">
        <Footer />
      </div>
    </div>

    <!-- Modal -->
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__zoomIn animate__faster"
      leave-active-class="animate__animated animate__zoomOut animate__faster"
    >
      <Modal :modal="modal" v-if="modal" />
    </transition>

    <!-- Landing -->
    <Landing />

    <ConfirmDialog></ConfirmDialog>
    <Toast position="bottom-right" />

  </div>
</template>

<script>
import { mapState } from 'vuex'
import user from '@/mixins/user'
import Landing from '@/components/layouts/Landing.vue'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import Toast from 'primevue/toast'

export default {
  name: 'App',
  mixins: [user],

  components: {
    Navbar: () => import('@/components/navbar/Default.vue'),
    NavbarAdmin: () => import('@/components/navbar/Admin.vue'),
    Footer: () => import('@/components/Footer.vue'),
    Modal: () => import('@/components/Modal.vue'),
    PatternArrow: () => import('@/components/patterns/Arrow.vue'),
    PatternBook: () => import('@/components/patterns/Book.vue'),
    PatternCube: () => import('@/components/patterns/Cube.vue'),
    PatternCurve: () => import('@/components/patterns/Curve.vue'),
    PatternTilt: () => import('@/components/patterns/Tilt.vue'),
    PatternTriangle: () => import('@/components/patterns/Triangle.vue'),
    PatternWave: () => import('@/components/patterns/Wave.vue'),
    Landing,
    ConfirmDialog,
    ConfirmPopup,
    Toast
  },

  mounted() {
    if (this.user.token.accessToken) {
      this.$store.dispatch('user/get')
    }

    this.$store.dispatch('servers/get')
    this.$store.dispatch('players/getOnline')

    if (this.user.uuid) {
      this.$store.dispatch('chat/fetchMessages')
    }
  },

  computed: {
    ...mapState({
      modal: state => state.modal,
      error: state => state.error
    }),

    getPattern() {
      if (!this.$route.meta.layout?.pattern) return
      return 'pattern-' + this.$route.meta.layout?.pattern
    },

    isHeader() {
      return this.$route.matched[0]?.components.header
    },

    isNavbarExpanded() {
      if (this.$route.meta.layout?.expandNavbar && !this.$route.meta.noPaddingNavbar) return true
      if (this.isHeader && !this.$route.meta.noPaddingNavbar) return true
      return false
    },

    isSidebar() {
      return this.$route.matched[0]?.components.sidebar
    },

    isFooter() {
      return !this.$route.meta.layout?.hideFooter
    },

    isFullPage() {
      return this.$route.meta.layout?.fullPage
    },

    getNavbar() {
      if (this.$route.meta.admin) return 'NavbarAdmin'
      else return 'Navbar'
    }
  },

  sockets: {
    connect() {
      if (this.user.token.accessToken) {
        this.$socket.client.emit('token', this.user.token.accessToken)
        this.$store.dispatch('chat/fetchMessages')
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

@import '@/assets/dependencies/fontawesome/scss/fontawesome.scss';
@import '@/assets/dependencies/fontawesome/scss/solid.scss';
@import '@/assets/dependencies/fontawesome/scss/light.scss';
@import '@/assets/dependencies/fontawesome/scss/regular.scss';
@import '@/assets/dependencies/fontawesome/scss/duotone.scss';
@import '@/assets/dependencies/fontawesome/scss/brands.scss';

@import "@/assets/dependencies/_bootstrap.scss";
@import "@/assets/dependencies/_fonts.scss";

@import '~pretty-checkbox/src/pretty-checkbox.scss';

@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';

@import "@/assets/scss/mc-colors.scss";
@import "@/assets/scss/navbar.scss";
@import "@/assets/scss/framework.scss";
@import "@/assets/scss/box.scss";
@import "@/assets/scss/header.scss";
@import "@/assets/scss/sidebar.scss";
@import "@/assets/scss/content.scss";
@import "@/assets/scss/footer.scss";

@import '~animate.css/animate.css';
</style>
