import Api from '@/api'

export default {

  async getMessages( cb ) {
    const result = await Api.get( `v1/chat` )
    cb( result )
  },

  async send( data, cb ) {
    const result = await Api.post( `v1/chat`, data )
    cb( result )
  },

  async statistics( cb ) {
    const result = await Api.get( `v1/chat/statistics` )
    cb( result )
  },

}
