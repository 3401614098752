module.exports = [
  {
    path: '/konto',
    name: 'Account',
    components: {
      default: () => import('@/views/pages/account/Overview.vue'),
      sidebar: () => import('@/views/sidebars/Account.vue')
    }
  },

  {
    path: '/konto/personvern',
    name: 'AccountPrivacy',
    components: {
      default: () => import('@/views/pages/account/Privacy.vue'),
      sidebar: () => import('@/views/sidebars/Account.vue')
    }
  },

  {
    path: '/konto/passord',
    name: 'AccountPassword',
    components: {
      default: () => import('@/views/pages/account/Password.vue'),
      sidebar: () => import('@/views/sidebars/Account.vue')
    }
  },

  {
    path: '/konto/stream',
    name: 'AccountStream',
    components: {
      default: () => import('@/views/pages/account/Stream.vue'),
      sidebar: () => import('@/views/sidebars/Account.vue')
    }
  },

  {
    path: '/konto/ut',
    name: 'AccountLogout',
    component: () => import('@/views/pages/account/Logout.vue')
  }
]
