import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import core from './routes/core'
import help from './routes/help'
import players from './routes/players'
import games from './routes/games'
import account from './routes/account'
import highscore from './routes/highscore'
import statistics from './routes/statistics'
import settings from './routes/settings'

const routes = [
  ...core,
  ...help,
  ...players,
  ...games,
  ...account,
  ...highscore,
  ...statistics,
  ...settings,

  {
    path: '*',
    name: '404',
    component: () => import('@/views/pages/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if(to.meta.stopToTop) return {}
    return { x: 0, y: 0 }
  }
})

export default router
